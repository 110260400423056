<template>
  <div class="campus">
    <banner-bg :type="6">
      <div class="campus-base">
        <new-banner
          :bannerList="bannerList"
          :height="600"
        ></new-banner>
      </div>
    </banner-bg>

    <div class="word-block">
      <banner-bg
        :type="12"
        :defaultBg="require('@/assets/image/Home/WordCommonBg.jpg')"
        bgColor="#ffffff"
      >
        <div class="title-body">
          <div class="top-block">
            <p class="p1"><span>{{titleConfig.schoolTitle1}}</span></p>
            <div class="word-btn">
              <a
                class="link"
                :href="aLink"
                target="_blank"
              >立即登录</a>
              <div class="desc">申请开通：team@eqibian.com</div>
            </div>
          </div>
          <div class="clear"></div>
          <p class="p2">{{titleConfig.schoolTitle2}}</p>
          <p class="p3">{{titleConfig.schoolTitle3}}</p>
        </div>
      </banner-bg>

    </div>
    <div class="campus-body">
      <div
        class="campus-body-item"
        v-for="(data, index) in bodyData"
        :key="index"
      >
        <Title
          :title="data.title"
          :desc="data.desc"
        ></Title>
        <div class="campus-body-content">
          <ul :class="`data-${data.type}`">
            <li
              v-for="(item, index) in data.data"
              :key="item.title"
            >
              <div :class="['img', data.type == 1 ? index < 4 ? 'bg-1-4' : index < 8 ? 'bg-5-8' : 'bg-9-12' : '']">
                <img :src="item.pic" />
              </div>
              <Title
                :is-small="true"
                :title="item.title"
                :desc="item.desc"
              ></Title>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import { getBanners } from '@/api/common';
import { mapState } from 'vuex';
import { getUserInfo } from "@/api/user"

import Title from '@/components/Title';
import { SCHOOL } from '@/utils/page-resource';
import BannerBg from '@/components/BannerBg.vue';
import NewBanner from '@/components/NewBanner.vue';

export default {
  components: {
    Title,
    BannerBg,
    NewBanner
  },
  data() {
    return {
      isStudent: false,
      aLink: null,
      bannerList: [
        // {id: 1, img: 'https://upfiles.heclouds.com/onenet-mall/onenet-mall/2020/10/22/1552dfda79fd015fd28a3f199ba5c3c0.png'},
        // {id: 2, img: 'https://upfiles.heclouds.com/onenet-mall/onenet-mall/2020/10/22/1552dfda79fd015fd28a3f199ba5c3c0.png'},
      ],
      bodyData: [
        { data: SCHOOL.bodyData1, type: 1, title: '高效有趣的创意编程互动课堂', desc: '面向教育部门、学校、机构的人工智能编程教育平台' },
        { data: SCHOOL.bodyData2, type: 2, title: '教育部门的人工智能编程教育区域监管平台', desc: '在线跟踪辖区整体的人工智能编程教育情况，制定统一发展战略' },
        { data: SCHOOL.bodyData3, type: 2, title: '学校领导的高效教务管理及课程规划工具', desc: '在线跟踪学校的教务管理，监控学校学情，制定全校教务管理规划' },
        { data: SCHOOL.bodyData4, type: 2, title: '授课教师的智能高效教学管理神器', desc: '贯穿课前备课、课中教学、课后点评全场景' },
        { data: SCHOOL.bodyData5, type: 2, title: '学生的人工智能编程好伙伴', desc: '丰富的课程资源，完善的编程工具全方位支撑' },
        { data: SCHOOL.bodyData6, type: 2, title: '家长的同步助学好帮手', desc: '家长在家随时可关注孩子的学习情况，指导编程' }
      ]
    };
  },
  computed: mapState({
    titleConfig: (state) => state.siteConfigs,
  }),
  created() {
    this.getBannerList();
    if (this.titleConfig.backDomain) {
      this.aLink = `${window.location.protocol}//${this.titleConfig.backDomain}`;
    } else {
      this.aLink = 'http://teaching.eqibian.com/';
    }
  },
  methods: {
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'xydb',
        platform: 'yqb',
        isPortal: '1'
      }
      getBanners(param).then(res => {
        if (res.success) {
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/campus.scss';
</style>